<template>
  <div class="users-management">
    <users-management-nav-bar/>
    <router-view></router-view>
  </div>
</template>

<script>
import UsersManagementNavBar from "@/components/template/usersManagement/Navbar";
export default {
  name: "UsersManagement",
  components: {
    UsersManagementNavBar
  }
}
</script>

<style lang="scss" scoped>
.users-management {
  background: #F5F5F5;
  padding: 150px 50px 63px 50px;
  display: flex;
  min-height: 100vh;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
    padding: 104px 20px 63px 20px;
  }
}
</style>
