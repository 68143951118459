<template>
  <div class="nav-bar">
    <router-link tag="h1" to="/admin/users-management" class="nav-bar__title">{{ $t('manage-users') }}</router-link>
    <div class="nav-bar__links">
      <router-link
          v-for="(link, index) in links"
          :to="link.path"
          :key="index"
          class="nav-bar__links-link"
      >
        {{ link.text }}
      </router-link>
    </div>
    <ui-menu v-model="visibleSettings">
      <template #activator>
        <img alt="" :src="require(`@/assets/svg/icon-settings-${settingsColor}.svg`)" class="nav-bar__settings" />
      </template>
      <template #body>
        <div class="sort">
          <router-link
              v-for="(link, index) in links"
              :to="link.path"
              :key="index"
              class="nav-bar__links-link"
              @click.native="visibleSettings = !visibleSettings"
          >
            {{ link.text }}
          </router-link>
        </div>
      </template>
    </ui-menu>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  components: {
    UiMenu: () => import('@/components/ui/UiMenu'),
  },
  data() {
    return {
      links: [
        { id: 0, text: this.$t('add-new-user'), path: '/admin/users-management/add-new-user' },
        { id: 1, text: this.$t('deleted-users'), path: '/admin/users-management/deleted-users' },
      ],
      visibleSettings: false,
    }
  },
  computed: {
    settingsColor () {
      return this.visibleSettings ? 'red' : 'black'
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-bar {
  position: relative;
  max-width: 302px;
  width: 100%;

  @media (max-width: 1200px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    width: 100%;
    max-width: 100%;
    z-index: 11;
  }

  &__title {
    margin-bottom: 40px;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    color: #343432;
    white-space: nowrap;
    cursor: pointer;

    @media (max-width: 1200px) {
      margin-bottom: 0;
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media (max-width: 1200px) {
      display: none;
    }

    .router-link-active {
      color: red;
    }

    &-link {
      font-weight: 400;
      font-size: 16px;
      line-height: 15px;
      color: #343432;
      cursor: pointer;
      white-space: nowrap;
    }
  }

  &__settings {
    display: none;

    @media (max-width: 1200px) {
      display: inline-block;
    }
  }

  .sort {

    .router-link-active {
      color: red;
    }
  }
}
</style>
